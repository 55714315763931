import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import './NavBar.css';

export default function NavBar(){
    const location = useLocation();

    return(
        <div className="NavBar-container">
            <ul>
                <li style={location.pathname === "/home" ? {"backgroundColor":"#EDD2C5"}: null}><Link to="/home" id="home-link">Home</Link></li>
                <li style={location.pathname === "/explore-data" ? {"backgroundColor":"#EDD2C5"}: null}><Link to="/explore-data" id="explore-data-link">Explore Data</Link></li>
                <li style={location.pathname.indexOf("project-planning") > -1 ? {"backgroundColor":"#EDD2C5"}: null}><Link to="/project-planning/start" id="project-planning-link">Project Planning</Link></li>
                {/* <li style={location.pathname === "/submit-project-info" ? {"backgroundColor":"#EDD2C5"}: null}><Link to="/submit-project-info" id="submit-info-link">Submit Project Info</Link></li> */}
                {/* <li style={location.pathname === "/import-data" ? {"backgroundColor":"#EDD2C5"}: null}><Link to="/import-data" id="import-data-link">Import WPD Data</Link></li> */}
                <li style={location.pathname === "/watershed-reporting" ? {"backgroundColor":"#EDD2C5"}: null}><Link to="/watershed-reporting" id="watershed-reporting-link">Watershed Reporting</Link></li>
                {/* <li style={location.pathname === "/workshop" ? {"backgroundColor":"#EDD2C5"}: null}><Link to="/workshop" id="watershed-reporting-link">Workshop</Link></li> */}
            </ul>
        </div>
    )
}